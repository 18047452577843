/* eslint-disable prettier/prettier */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

let firebaseConfig = {
  apiKey: 'AIzaSyBHIYARrc6zmBrL6b-cU48r04MhY5hpafA',
  authDomain: 'video-chat-vuejs.firebaseapp.com',
  projectId: 'video-chat-vuejs',
  storageBucket: 'video-chat-vuejs.appspot.com',
  messagingSenderId: '560139847817',
  appId: '1:560139847817:web:0e6901a61d18b6a67ade58',
}

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
export default firebaseApp.firestore()

